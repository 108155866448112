import { add as addEvent } from 'bianco.events'

export default function injectSvg(svgContainer: Element) {
  const svgUrl = svgContainer.getAttribute('data-src') as string
  const svgElement = svgContainer

  // Handle the loaded, cache-busted SVG
  function onLoadSVG(this: XMLHttpRequest) {
    svgElement.setAttribute('aria-hidden', 'true')
    svgElement.className = 'u-icon-sprite'
    svgElement.innerHTML = this.responseText

    window.dispatchEvent(new CustomEvent('icons-loaded'))
  }

  // Load the SVG separately without cache
  const SVGRequest = new XMLHttpRequest()

  addEvent(SVGRequest, 'load', onLoadSVG)
  addEvent(SVGRequest, 'error abort', console.log)

  SVGRequest.open('GET', svgUrl, true)
  SVGRequest.send()
}

/**
 * Polyfills SVG usage for older browsers and helps using the browser cache for symbols.
 */
addEvent(document, 'DOMContentLoaded', () => {
  const svgContainer = document.querySelector('.u-icon-source')

  if (svgContainer) {
    injectSvg(svgContainer)
  }
})
