import { createApp } from 'vue'

// side effects
import './webpack-runtime-settings'
import './util/misc/inject-svg'
import './util/misc/sprout-form-enhancer'
import initSentry from './util/misc/sentry'
import getGlobalComponents from './global-components/get-global-components'

// start sentry
initSentry()

const componentsMap = getGlobalComponents()

function init() {
  // find all the islands to mount
  const components = Array.from(document.querySelectorAll('[is]'))

  return components.map((component) => {
    // crate a new app for each component
    // unfortunately with vue 3 we can't reuse the same app instance twice
    const app = createApp({
      components: {
        // make all the global components available in its template
        ...componentsMap,
      },
      mounted() {
        // replace the placeholder node with the vue instance root
        component.replaceWith(this.$el)
      },
      template: component.outerHTML,
    }).mount(component)

    return app
  })
}

// start the application
init()
