import $ from 'bianco.query'
import { add } from 'bianco.events'

const [contactForm] = $('.contact-form')

const onSubmit = () => {
  const [submitDiv] = $('.submit', contactForm)
  const [submitButton] = $('button', submitDiv)
  contactForm.classList.add('is-submitting')
  submitButton.setAttribute('disabled', 'disabled')
  submitDiv.classList.add('is-loading')
}

if (contactForm) {
  add(contactForm, 'onSproutFormsSubmit', onSubmit, false)
}
