/* eslint-disable camelcase, no-underscore-dangle, @typescript-eslint/no-explicit-any */

export type WindowGlobalsBridge = {
  static: string
  csrf: string
  debug: boolean
  gitCommitHash: string
  sentryDSN: string
  cmsEditMode: boolean
  apiBase: string
  currentLanguage: string
  routes: Record<string, string>
  translations: Record<string, unknown>
}

const fallback: WindowGlobalsBridge = {
  static: __webpack_public_path__,
  csrf: '',
  debug: false,
  gitCommitHash: '',
  sentryDSN: '',
  apiBase: '',
  currentLanguage: '',
  cmsEditMode: false,
  routes: {},
  translations: {},
}

export default (window as any)?.__js_bridge ?? (fallback as WindowGlobalsBridge)
