
  import $ from 'bianco.query'
  import { add as addEvent, remove as removeEvent } from 'bianco.events'
  import { elementOffsetTop, scrollTop } from 'bianco.viewport'
  import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    useSlots,
    PropType,
    h,
  } from 'vue'

  export default defineComponent({
    props: {
      as: {
        type: String,
        required: true,
      },
      anchors: {
        type: Array as PropType<string[]>,
        required: true,
      },
    },
    setup(props) {
      const anchorsOffset = ref<number[]>([])
      const activeIndex = ref<number>(0)
      const root = ref<HTMLUListElement>()
      const slots = useSlots()

      const setAnchorOffsets = () => {
        anchorsOffset.value = props.anchors.map((s) =>
          Math.floor(elementOffsetTop($<HTMLElement>(s)[0])),
        )
      }
      const onScroll = () => {
        const scrollPosition = scrollTop()
        // reverse all the anchor offsets to find the active index starting from the last one
        const currentActiveIndex = [...anchorsOffset.value]
          .reverse()
          .findIndex((o) => scrollPosition >= o)
        // the right active index must be remapped because we have reversed the anchorOffsets list
        // the findIndex method will give use always the inverse index of the current active menu item
        const mapIndex = (index: number) =>
          anchorsOffset.value.length - 1 - index

        // if an active index was found we map it to the proper one otherwise we fall back to the first item
        activeIndex.value =
          currentActiveIndex > -1 ? mapIndex(currentActiveIndex) : 0
      }

      const onLinkClick = (event: Event) => {
        const link = event.target as HTMLLinkElement
        const anchorLink = link.getAttribute('href')
        const [anchorTarget] = $(anchorLink as string)

        if (anchorTarget) {
          event.preventDefault()
          anchorTarget.scrollIntoView({
            behavior: 'smooth',
          })
        }
      }

      onMounted(() => {
        addEvent(window, 'scroll', onScroll, { passive: true })
        addEvent(window, 'resize orientationchange', setAnchorOffsets, {
          passive: true,
        })

        addEvent($('a', root.value), 'click', onLinkClick)

        setAnchorOffsets()
        onScroll()
      })

      onUnmounted(() => {
        removeEvent(window, 'scroll', onScroll)
        removeEvent(window, 'resize orientationchange', setAnchorOffsets)

        removeEvent($('a', root.value), 'click', onLinkClick)
      })
      return () => {
        return slots.default
          ? h(
              props.as,
              {
                ref: root,
              },
              [
                slots.default({
                  activeIndex: activeIndex.value,
                }),
              ],
            )
          : null
      }
    },
  })
