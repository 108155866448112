<template>
  <div class="scroll-reveal" :class="`scroll-reveal--${facet}`" ref="target">
    <component
      class="scroll-reveal__wrapper"
      :class="[
        wrapperClass,
        animate
          ? 'scroll-reveal__wrapper--is-visible'
          : 'scroll-reveal__wrapper--not-visible',
      ]"
      :is="wrapperTagName"
    >
      <slot />
    </component>
  </div>
</template>

<script lang="js">
  import { onMounted, onUnmounted, ref } from 'vue'

  export default {
    name: 'AnimatedComponent',
    props: {
      facet: {
        type: String,
        default: ''
      },
      wrapperTagName: {
        type: String,
        default: 'div'
      },
      wrapperClass: {
        type: String,
        default: ''
      }
    },
    setup() {
      const target = ref()
      const animate = ref(false)
      const observer = new IntersectionObserver(
          ([entry]) => {
            animate.value = entry.isIntersecting

            if (entry.isIntersecting) {
              observer.disconnect();
            }
          },
          {
            threshold: 0.5
          }
      )

      onMounted(() => {
        observer.observe(target.value)
      })

      onUnmounted(() => {
        observer.disconnect()
      })

      return {
        animate,
        target
      }
    }
  }
</script>
